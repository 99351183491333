// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exercise-process-js": () => import("./../../../src/pages/exercise/process.js" /* webpackChunkName: "component---src-pages-exercise-process-js" */),
  "component---src-pages-exercise-select-js": () => import("./../../../src/pages/exercise/select.js" /* webpackChunkName: "component---src-pages-exercise-select-js" */),
  "component---src-pages-exercise-summary-js": () => import("./../../../src/pages/exercise/summary.js" /* webpackChunkName: "component---src-pages-exercise-summary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-cancel-js": () => import("./../../../src/pages/payment/cancel.js" /* webpackChunkName: "component---src-pages-payment-cancel-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment/success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-qna-js": () => import("./../../../src/pages/qna.js" /* webpackChunkName: "component---src-pages-qna-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-tnc-js": () => import("./../../../src/pages/tnc.js" /* webpackChunkName: "component---src-pages-tnc-js" */),
  "component---src-pages-token-js": () => import("./../../../src/pages/token.js" /* webpackChunkName: "component---src-pages-token-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */)
}

