import React from "react"
import PropTypes from "prop-types"
import { StoreProvider } from "easy-peasy"
import { ThemeProvider } from "styled-components"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"

import store from "@store"
import theme from "@theme"

const wrapWithProvider = ({ element }) => (
  <StoreProvider store={store}>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </MuiThemeProvider>
  </StoreProvider>
)

wrapWithProvider.propTypes = {
  element: PropTypes.node.isRequired,
}

export default wrapWithProvider
