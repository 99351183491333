import { createMuiTheme } from "@material-ui/core/styles"
import createShadows from "@utils/shadows"

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
  },
}

const defaultTheme = createMuiTheme({ breakpoints })

const theme = createMuiTheme({
  breakpoints,
  shadows: createShadows("#c2c2c2"),
  typography: {
    fontFamily: "Noto Sans HK",
    h1: {
      fontWeight: "500",
      fontSize: "4.5rem",
      lineHeight: 1.25,
    },
    h2: {
      fontWeight: "500",
      fontSize: "1.25rem",
      lineHeight: 1.25,
    },
    h3: {
      fontWeight: "500",
      fontSize: "3rem",
      lineHeight: 1.25,
    },
    h4: {
      fontWeight: "700",
      fontSize: "2.5rem",
      lineHeight: 1.25,
    },
    subtitle1: {
      fontWeight: "400",
      fontSize: "2.25rem",
    },
    subtitle2: {
      fontWeight: "400",
      fontSize: "2rem",
    },
    body1: {
      fontWeight: "400",
      fontSize: "1.25rem",
    },
    body2: {
      fontWeight: "400",
      fontSize: "1rem",
    },
  },
  palette: {
    primary: {
      light: "#fffddb",
      main: "#007bff",
      dark: "#007bff",
      contrastText: "#000",
    },
    secondary: {
      light: "#ffcb4c",
      main: "#007bff",
      dark: "#946c00",
      contrastText: "#000",
    },
    error: {
      light: "#ff7a70",
      main: "#F54444",
      dark: "#bb001c",
      contrastText: "#fff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    text: {
      primary: "#000",
      secondary: "#fff",
    },
    divider: "#ddd",
    background: {
      default: "#fff",
      header: "#080c33",
      footer: "#2f3639",
    },
    actionText: {
      active: "rgb(0, 123, 255)",
      hover: "rgba(201, 154, 19, .6)",
      hoverOpacity: 0.6,
      selected: "rgba(201, 154, 19, .8)",
      selectedOpacity: 0.8,
      disabled: "rgba(201, 154, 19, .3)",
      disabledBackground: "rgba(201, 154, 19, .3)",
      disabledOpacity: 0.3,
      focus: "rgba(201, 154, 19, .8)",
      focusOpacity: 0.8,
      activatedOpacity: 0.8,
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        minHeight: "3rem",
        [defaultTheme.breakpoints.up("sm")]: {
          minHeight: "3rem",
        },
      },
      gutters: {
        paddingLeft: ".25rem",
        paddingRight: ".25rem",
        [defaultTheme.breakpoints.up("sm")]: {
          paddingLeft: ".25rem",
          paddingRight: ".25rem",
        },
      },
    },
    MuiDialog: {
      paper: {
        [defaultTheme.breakpoints.down("xs")]: {
          margin: "1rem",
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "1rem",
      },
    },
    MuiListItem: {
      root: {
        justifyContent: "center",
        color: "#4D4D4D",
      },
    },
  },
})

export default theme
