/* eslint-disable no-param-reassign */
import { action } from "easy-peasy"

export default {
  accessToken: undefined,
  updateAccessToken: action((state, payload) => {
    state.accessToken = payload
  }),
  deleteAccessToken: action((state) => {
    state.accessToken = undefined
  }),
}
