import { persist } from "easy-peasy"

import auth from "./auth"
import user from "./user"

const storeModel = {
  localStorage: persist(
    { auth },
    {
      storage: "localStorage",
    }
  ),
  user,
}

export default storeModel
